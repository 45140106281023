<template>
    <v-container>
      <v-row dense>
            <v-col>
                <v-card>
                    <v-card-title class="col-12 justifyContentCenter">
                        <h4>{{ translate.title }}</h4>
                    </v-card-title>
                    <v-card-title class="col-12">
                       <p> {{ translate.aboutUs }} </p>
                    </v-card-title>
                </v-card>
            </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
            <v-card>
              <v-img
                  class="image white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/about_us.jpg"
                  alt="About Us"
              >
              </v-img>
            </v-card>
        </v-col>
         <v-col cols="12" sm="8">
            <v-row dense>
                 <v-col>
                    <v-card>
                         <v-card-text>
                            <v-list dense>
                                <v-subheader>
                                    <h3>
                                        <v-icon size="40" color="blue darken-2" aria-hidden="false">
                                        </v-icon>
                                        {{ translate.r_and_d_title }}
                                    </h3>
                                </v-subheader>
                                <v-list-item
                                    v-for="(item, i) in translate.items"
                                    :key="i"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="blue darken-2" v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title 
                                            v-text="item.text"
                                            class="wrap-text">
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>
     </v-row>
     <v-row dense>
        <v-col cols="12">
            <v-card>
              <v-card-title class="col-12 justifyContentCenter">
                <h4> {{ translate.examplesHeading }} </h4>
              </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                    <v-row dense>
                        <v-col class="col-12" v-for="(item,i) in translate.examplesForWork"
                        :key="i">
                            <v-card>
                                    <v-card-title>
                                    <h4>
                                        <v-icon size="25" color="blue darken-2" aria-hidden="false">
                                            {{ item.icon }}
                                        </v-icon>
                                        {{ item.header }}
                                    </h4>
                                </v-card-title>
                                    <v-card-text>
                                    <li 
                                    v-for="(listItem, j)
                                    in item.listItems"
                                    :key="'listItemKey'+j"> {{ listItem }}</li>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
               </v-card-text>
            </v-card>
        </v-col>
     </v-row>
    </v-container>
</template>

<script>

import { AboutUsContent } from '../translation/en.js';

export default {
    name: 'AboutUs',
    data: () => ({
    translate: AboutUsContent,
  }),
}

</script>


<style scoped>

.image {
  height: 410px;
}

.wrap-text {
  white-space: normal;
}

@media screen and (max-width:600px) {
 .image {
    height: 350px;
  }
}

</style>