<template>
  <v-container>
    <v-row dense>
        <v-col cols="12" sm="4">
            <v-card>
              <v-img
                  class="image white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/services.png"
                  alt="Services"
              >
                <v-card-title>
                    <h4>{{ translate.title }}</h4>
                </v-card-title>
              </v-img>
            </v-card>
        </v-col>
         <v-col cols="12" sm="8">
            <v-row dense
              v-for="(item,i) in translate.services"
              :key="i">
                 <v-col>
                    <v-card>
                         <v-card-title>
                            <h4>
                                <v-icon size="25" color="blue darken-2" aria-hidden="false">
                                    {{ item.icon }}
                                </v-icon>
                                {{ item.header }}
                            </h4>
                        </v-card-title>
                         <v-card-text>
                           <li 
                            v-for="(listItem, j)
                            in item.listItems"
                            :key="'listItemKey'+j"> {{ listItem }}</li>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12">
            <v-card>
              <v-card-title class="col-12 justifyContentCenter">
                <h4> {{ translate.managementSupport.title }} </h4>
              </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3"
                    v-for="(item, i) in translate.managementSupport.items"
                    :key="'managementSupport'+i">
                    <div class="textAlignCenter">
                        <v-icon size="70" color="blue darken-2" aria-hidden="false">
                           {{ item.icon }}
                        </v-icon>
                        <h3>{{ item.header }}</h3>
                        <v-card-text> 
                           {{ item.cardText }}
                        </v-card-text>
                    </div>
                  </v-col>
                </v-row>
               </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { servicesContent } from '../translation/en.js';

export default {
  name: 'Services',
  data: () => ({
    translate: servicesContent
  }),
}
</script>

<style scoped>

.image {
  height: 590px;
}

@media screen and (max-width:600px) {
 .image {
    height: 400px;
  }
}

</style>
