<template>
  <v-container>
    <v-row dense class="row">
        <v-col cols="12">
            <v-card>
              <v-img
                  class="image white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/home_page_banner.jpg"
                  alt="oncoKnow banner"
              >
                <v-card-title class="col-12">
                  <h4> {{ translate.title }} </h4>
                </v-card-title>
              </v-img>
            </v-card>
        </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12">
            <v-card>
              <v-card-title class="col-8 offset-2 justifyContentCenter">
                <p> {{ translate.whyChooseUsTitle }} </p>
              </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                <v-row>
                  <v-col 
                    cols="12"
                    sm="4"
                    v-for="(item, i) in translate.chooseUsReasons"
                    :key="i">
                    <div class="textAlignCenter">
                      <v-icon size="70" color="blue darken-2" aria-hidden="false">
                       {{ item.icon }}
                      </v-icon>
                      <h3>{{ item.heading }}</h3>
                      <p>
                        {{ item.paragraph }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
               </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12">
            <v-card>
              <v-card-title class="col-12 justifyContentCenter">
                <h4> {{ translate.services }} </h4>
              </v-card-title>
               <v-divider></v-divider>
              <v-card-text>
                <Services/>
              </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Services from './Services.vue';
import { homePageContent } from '../translation/en.js';

export default {
  name: 'Home',
  components: {
    Services
  },
  data: () => ({
    translate: homePageContent
  }),
}
</script>

<style scope>

.image {
  height: 400px;
}

.justifyContentCenter {
  justify-content: center;
}

.textAlignCenter {
  text-align: center;
}

.experienceRow {
  background-color: #1976D2;
  min-height: 50px;
  border-radius: 20px;
}

@media screen and (max-width:600px) {
 .image {
    height: 235px;
  }
}

</style>
