<template>
  <v-app>
    <v-app-bar
      app
      class="primary"
      dark
      height="50"
    >
      <v-img
        class="mx-2 backColor"
        src="./assets/oncoKnow logo_transparent-01.png"
        max-height="90"
        max-width="90">
      </v-img>
      <v-btn
        v-for="link in links"
        :key="`${link.label}-header-link`"
        text
        rounded
        :to="link.url"
      >
          {{ link.label }}
      </v-btn>
    </v-app-bar>
    <v-main class="main">
        <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer
  },
  data: () => ({
    links: [
      {
        label: 'Home',
        url: '/'
      },
      {
        label: 'About Us',
        url: '/aboutus'
      },
      {
        label: 'Contact Us',
        url: '/contactus'
      }
    ]
  }),
};
</script>

<style scope>

.main {
  width: 100%;
  min-height: 600px;
}

.backColor {
  background-color: white;
}

</style>
