import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue';
import ContactUs from '../views/ContactUs.vue';
import AboutUs from '../views/AboutUs.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: ContactUs
  },
  {
    path: '*',
    name: 'Error',
    component: () => import( '../views/Error.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history'
})

export default router
