<template>
    <v-container>
      <v-row dense>
          <v-col class="xs-6">
            <iframe 
              src="https://docs.google.com/forms/d/e/1FAIpQLSevwcFOFN1Dmt3LdIK176XYxRe63wbZB4spAcaDbRytRawsAQ/viewform?embedded=true"
              width="500"
              height="500"
              frameborder="0"
              marginheight="0"
              marginwidth="0">
              Loading…</iframe>
          </v-col>
          <v-col class="xs-6">
            <v-card
              class="mx-auto"
              max-width="450">
                <v-card-title class="col-12">
                    <p> {{ translate.title }}</p>
                </v-card-title>
                <v-card-text>
                  <h4>
                      <v-icon size="20" color="blue darken-2" aria-hidden="false">
                          {{ translate.emailIcon }}
                      </v-icon>
                      {{ translate.email }}
                  </h4>
                </v-card-text>
                <v-img
                  class="imageWidth"
                  height="400"
                  src="../assets/contactUs.png"
                  alt="Contact Us"
                >
                </v-img>     
            </v-card>
          </v-col>
      </v-row>
    </v-container>
</template>

<script>

import { ContactUsContent } from '../translation/en.js';

export default {
    name: 'ContactUs',
    data: () => ({
      translate: ContactUsContent
    })
}

</script>


<style scoped>

.imageWidth {

}

@media screen and (max-width:600px) {
}

</style>