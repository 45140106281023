<template>
    <v-footer
      dark
      padless
    >
      <v-card
        flat
        tile
        class="white--text text-center fullWidth primary"
      >
        <v-card-text>
          <v-btn
            v-for="icon in translate.icons"
            :key="icon.id"
            class="mx-8 white--text"
            icon
            :href="icon.link"
            target="_blank"
          >
            <v-icon size="24px">
              {{ icon.id }}
            </v-icon>
            <span>{{ icon.text }} </span>
          </v-btn>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-text class="white--text">
           <strong> &copy; {{ new Date().getFullYear() }} 
             {{ translate.brandName }}
           </strong>
        </v-card-text>
      </v-card>
    </v-footer>

</template>

<script>

import { FooterContent } from '../translation/en.js';

export default {
  name: 'Footer',
  data: () => ({
    translate: FooterContent
  }),
}
</script>

<style scope>

.fullWidth {
    width: 100%;
}

</style>
