export const homePageContent = {
    title: 'Welcome to expertise for niche, specialist drug development',
    whyChooseUsTitle: 'We provide support for R&D projects, sponsored by industry or academia',
    chooseUsReasons: [{
        icon: 'mdi-check-circle-outline',
        heading: 'R&D knowhow',
        paragraph: '20+ years hands-on experience in drug development'
    },{
        icon: 'mdi-nature',
        heading: 'Sustainability',
        paragraph: 'Strategic & technical support throughout the product life cycle'
    },{
        icon: 'mdi-access-point-network',
        heading: 'Professional network',
        paragraph: 'KOL contacts in industry and academia, globally'
    }],
    services: 'Strategic & scientific consultancy'
}

export const servicesContent = {
    title: '',
    services: [{
        icon: 'mdi-bee-flower',
        header: 'Discovery',
        listItems: [
            'Evaluation of target identification, target validation activities',
            'Competitive & clinical landscape assessment'
        ]
    },{
        icon: 'mdi-microscope',
        header: 'Pre-clinical',
        listItems: [
            'Evaluation of development candidate profile',
            'Design of proof-of concept & pharmacology studies',
            'Gap analysis to CTA/ IND submission'
        ]
    },{
        icon: 'mdi-translate',
        header: 'Translational',
        listItems: [
            'Tumour type/ indication selection',
            'Biomarkers verification/ validation plans',
            'Diagnostic/ companion diagnostic development plan',
        ]
    },{
        icon: 'mdi-stethoscope',
        header: 'Clinical',
        listItems: [
            'Study concept & study design',
            'Integrated/ Clinical Development Plan',
            'TPP development'
        ]
    }],
    managementSupport: {
        title: 'Management & operational support',
        items: [{
            icon: 'mdi-pencil-outline',
            header: 'Medical writing',
            cardText: 'Preparation of clinical study protocols & clinical study reports, regulatory documents (e.g. ODD, IMPD/ CTA, IND, MAA/ BLA)'
        },{
            icon: 'mdi-presentation',
            header: 'Stakeholders',
            cardText: 'Set-up and facilitation of Scientific Advisory Boards, preparation of documentation and presentation at Company Board & Investor presentations, interactions with regulatory authorities, investors, partners etc.'
        },{
            icon: 'mdi-database',
            header: 'Operations/ technical',
            cardText: 'Data review & analysis, study design & planning, programme/ project management, vendor selection & oversight'
        },{
            icon: 'mdi-cash-refund',
            header: 'Other',
            cardText: 'Preparation of funding applications (incl. EU H2020, national boards), publications/ educational material etc.examples'
        }]
    }
}

export const AboutUsContent = {
    title: 'Who We Are',
    aboutUs: 'The ambition of oncoKnow is to contribute and support the work of teams exploiting intriguing and exciting new (anticancer) drug development opportunities',
    r_and_d_icon: 'mdi-flag-plus',
    r_and_d_title: '20+ years of experience in leading R&D projects in:',
    examplesHeading: 'Examples of oncoKnow work: ',
    items: [
        { text: 'Oncology & rare diseases', icon: ' mdi-hospital' },
        { text: 'Small molecules, biological, cell & gene therapies', icon: 'mdi-size-s' },
        { text: 'In global pharma & biotech/ SME', icon: 'mdi-needle' },
        { text: 'In commercial R&D, academic research & service sector', icon: 'mdi-school-outline' },
        { text: 'Commercial/ co-development partnerships & public – private initiatives', icon: 'mdi-developer-board' },
        { text: 'Early pre-clinical, translational science, IND/ CTA enabling & clinical stages', icon: 'mdi-flask' },
        { text: 'EMA, FDA & national competent authorities', icon: 'mdi-file-document-multiple-outline' },
        { text: 'Broad scientific/ clinical professional network', icon: 'mdi-access-point-network' },
      ],
      examplesForWork: [{
        icon: 'mdi-current-ac',
        header: 'Scientific/ translational support',
        listItems: [
            'Development of pre-clinical & translational plans for an oligonucleotide targeting DNA Damage Response pathways, to support scheduling and combination rationale and set-up of biomarker studies to enable patient selection in the planned Phase II clinical study.',
            'Set-up and oversight of preclinical pharmacology and translational activities to support the Phase I/ Proof of Concept clinical development plans for a small molecule targeting cancer cell metabolism.',
            'Review of clinical landscape for a blood-based pan-cancer diagnostic for early detection.'
        ]
    },{
        icon: 'mdi-transition',
        header: 'Program management',
        listItems: [
            'Pivotal Phase II trial for an RNA vaccine involving 20 countries and over 160 clinical sites',
            'Biological projects to support feasibility and upstream/ downstream process development for a global Contract Manufacturing Organization',
            'Gene therapy product for a rare indication (from pre-clinical proof of concept work to first-in-human clinical trial)'
        ]
    }]
}

export const ContactUsContent = {
    title: 'Located in Amsterdam (The Netherlands)',
    email: 'info@oncoKnow.com',
    emailIcon: 'mdi-email'
}

export const FooterContent = {
    icons: [{
        id: 'mdi-linkedin',
        link: 'https://www.linkedin.com/in/konstantina-grosios-52780418/'
    },{
        id: 'mdi-email',
        link: 'mailto:info@oncoKnow.com',
        text: 'Email Us'
    }],
    brandName: 'oncoKnow'
}